<template>
  <div></div>
</template>

<script>
import { APPID, REDIRECT_URI } from "@/config";
import { mapActions } from "vuex";
import { clearStore, removeStore, getStore } from "../../lib/storage";
// import wechat from '../../lib/wechat';

export default {
  name: "oauth",
  methods: {
    ...mapActions(["geolocation"]),
    oauth(invitecode) {
      invitecode = invitecode ? invitecode : "";
      const appid = APPID;
      const redirect_uri = REDIRECT_URI;
      const SCOPE = "snsapi_userinfo";
      // let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&response_type=code&scope=${SCOPE}&state=STATE#wechat_redirect`

      let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
        redirect_uri
      )}&response_type=code&scope=${SCOPE}&state=${invitecode}#wechat_redirect`;
      console.log("url:", url);
      window.location.href = url;
    },
  },
  mounted() {
    // clearStore();
    if (getStore("startAddress")) {
      removeStore("startAddress");
    }
    this.$nextTick(() => {
      //   this.geolocation();
      this.oauth("");
    });
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/mixin.less";

.oauthBtn {
  .size(200px, 34px);
  margin: 30px auto;
  .bg(@brand-green);
  .color(#fff);
  .fontSize(32px);
  line-height: 34px;
  text-align: center;
}
</style>

